export const formatURL = (url) => {
  console.log('URL de entrada:', url); // Log de la URL original
  try {
    // Intentar parsear la URL
    const parsedUrl = new URL(url);

    // Construir el formato deseado: protocolo + hostname (sin puertos ni rutas)
    const formattedUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}`;
    console.log('URL formateada:', formattedUrl); // Log de la URL formateada

    return formattedUrl;
  } catch (error) {
    // Manejar errores con un mensaje claro
    console.error('Error al formatear URL:', error.message);

    return {
      error: true,
      message: 'URL Inválida. Por favor verifica el formato.',
      originalInput: url, // Devuelve la entrada original para mayor claridad
    };
  }
};