import axiosInstance from "../utils/AxiosConfig";
import { formatURL } from "../utils/functions/Format";

const WebsiteService = {
  obtenerWebsites: () => {
    try {
      const response = axiosInstance.get("/obtenerWebsites");
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  crearWebsite: (url) => {
    try {
      const url_formatted = formatURL(url);
      const response = axiosInstance.post("/crearWebsite", {url: url_formatted});
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  mostrarWebsite: (url) => {
    try {
      
      const response = axiosInstance.post("/mostrarWebsite", url);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  get_ratings: (url) => {
    try {
      
      const response = axiosInstance.post("/get_ratings", url)
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  get_comments: (url) => {
    try {
      
      const response = axiosInstance.post("/get_comments", {url: url});
      return response;
    } catch (error) {
      handleError(error);
    }

  },
  create_rating: (url, score) => {
    try {
      
      const response = axiosInstance.post("/create_rating", url, score);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  create_comment: (url, content) => {
    try {
      
      const response = axiosInstance.post("/create_comment", {url: url, content: content});
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  checkUrlOpenAi: (url) => {
    try {
      const response = axiosInstance.post('/check_url_open_ai', {url: url});
      return response;
    } catch (error) {
      handleError(error);
    }
  },
};

const handleError = (error) => {
  console.error("Error:", error);
  const status = error.response?.status || 500; 
  const message = error.response?.data?.message || "Error inesperado";
  throw {
    status,
    message,
    originalError: error, 
  };
};

export default WebsiteService;