import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import {

  MenuItem,

} from "@headlessui/react";

const ButtonSessions = ({ openModal }) => {
  const { user, logout } = useContext(AuthContext);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
      // Maneja los errores según tus necesidades
    }
  };

  return (
    <>
      {user ? (
        <MenuItem>
          <a
            onClick={handleLogout}
            href="#"
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none"
          >
            Cerrar Sesión
          </a>
        </MenuItem>
      ) : (
        <button
          onClick={openModal}
          className="text-white hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
        >
          Iniciar Sesión
        </button>
      )}
    </>
  );
};

export default ButtonSessions;
