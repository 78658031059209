import React, { useState, useContext } from "react";

const ScamsByCategory = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const openModal = (category) => {
    setModalContent({
      title: category,
      list: exampleData[category],
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const exampleData = {
    Website: [
      { name: "example1.com", searches: 4560 },
      { name: "example2.com", searches: 3900 },
      { name: "example3.com", searches: 3420 },
      { name: "example4.com", searches: 2700 },
      { name: "example5.com", searches: 1250 },
    ],
    Phone: [
      { name: "+1234567890", searches: 5600 },
      { name: "+9876543210", searches: 4900 },
      { name: "+1122334455", searches: 4500 },
      { name: "+5566778899", searches: 3200 },
      { name: "+9988776655", searches: 2400 },
    ],
    Crypto: [
      { name: "wallet123", searches: 3500 },
      { name: "crypto567", searches: 3000 },
      { name: "btc098", searches: 2700 },
      { name: "eth456", searches: 2200 },
      { name: "ltc789", searches: 1800 },
    ],
    IBAN: [
      { name: "DE89370400440532013000", searches: 1500 },
      { name: "GB82WEST12345698765432", searches: 1300 },
      { name: "FR7630006000011234567890189", searches: 1200 },
      { name: "IT60X0542811101000000123456", searches: 1000 },
      { name: "ES9121000418450200051332", searches: 900 },
    ],
    License: [
      { name: "ABC123", searches: 1800 },
      { name: "XYZ456", searches: 1500 },
      { name: "DEF789", searches: 1200 },
      { name: "GHI012", searches: 900 },
      { name: "JKL345", searches: 700 },
    ],
    Other: [
      { name: "Misc1", searches: 2300 },
      { name: "Misc2", searches: 2000 },
      { name: "Misc3", searches: 1700 },
      { name: "Misc4", searches: 1400 },
      { name: "Misc5", searches: 1000 },
    ],
  };
  return (
    <>
    <div className="p-4 px-6 md:px-32 flex justify-center">
    <ul className="w-full max-w-4xl grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-4 text-center">
        {Object.entries(exampleData).map(([category, items]) => (
        <li key={category}>
            <button
            className="flex items-center justify-center font-medium text-gray-300 hover:text-blue-400 transition-colors underline"
            onClick={() => openModal(category)}
            >
            <span className="inline-block w-3 h-3 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full mr-2"></span>
            {category}: {items.length.toLocaleString()} scams
            </button>
        </li>
        ))}
    </ul>
    </div>
      {/* Modal de detalles */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div className="bg-gray-900 text-gray-200 p-6 rounded-lg shadow-xl w-11/12 max-w-lg relative">
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-red-600 text-white px-3 py-1 rounded-full hover:bg-red-700"
            >
              X
            </button>
            <h3 className="text-xl font-bold mb-4 text-gradient bg-gradient-to-r from-red-500 to-red-700 bg-clip-text text-transparent">
              {modalContent.title} - Most Searched Scams
            </h3>
            <ul className="space-y-2">
              {modalContent.list.map((item, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center text-gray-400 hover:text-gray-200 transition-colors"
                >
                  <span className="text-red-500 font-bold">{item.name}</span>
                  <span className="text-sm text-gray-500">
                    {item.searches.toLocaleString()} searches
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default ScamsByCategory;
