import React, { useState } from "react";
import ModalAuth from "../components/common/ModalAuthentication"; // Cambié a mayúscula para seguir las convenciones de React

// Componente MobileOnlyPage para notificar a los usuarios que la app solo está disponible en web.
const MobileOnlyPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Función para manejar la apertura y cierre del modal
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gradient-to-r from-gray-900 via-black to-gray-900 text-center p-6">
      {/* Modal de autenticación */}
      <ModalAuth isOpen={isOpen} setIsOpen={setIsOpen} />

      {/* Contenido de la página */}
      <div className="bg-white text-gray-800 p-8 rounded-md shadow-lg max-w-lg">
        <h1 className="text-3xl md:text-4xl font-bold mb-4 text-gradient">
          Página solo disponible en versión web
        </h1>
        <p className="text-lg md:text-xl mb-6">
          Actualmente, esta página solo está disponible en la versión web de
          nuestro servicio. Estamos trabajando para llevar esta
          funcionalidad a la aplicación móvil.
        </p>
        <p className="text-md text-gray-600 mb-6">
          Mantente conectado y pronto te informaremos sobre la disponibilidad de
          nuestra app móvil para que puedas acceder desde cualquier lugar.
        </p>
      </div>

      {/* Botón para abrir el modal */}
      <button
        onClick={toggleModal}
        className="mt-8 px-6 py-3 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-md font-semibold hover:bg-secondary-dark transition duration-300"
      >
        Notificarme cuando la app esté disponible
      </button>
    </div>
  );
};

export default MobileOnlyPage;
