import {
  Button,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState, useContext, useEffect } from "react";
import AuthModal from "../common/ModalAuthentication";
import { AuthContext } from "../context/AuthContext";
import ButtonSessions from "../common/ButtonSession";
import logo from "../../images/logo1.png";
import profilePlaceholder from "../../images/profile.png";
import UserProfile from "../../Api/Perfil";

const navigationLinks = [];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar(){
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const [avatar, setAvatar] = useState(logo);

  useEffect(() => {
    if (user) {
      fetchProfileData();
    }
  }, [user]); // Solo se ejecuta si cambia `user`

  const fetchProfileData = async () => {
    try {
      const { data } = await UserProfile.mostrar_perfil();
      setAvatar(data.avatar_url || profilePlaceholder);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const toggleMenu = () => setIsOpen(!isOpen);
  const toggleModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <Disclosure as="nav" className="bg-gray-800">
      <AuthModal isOpen={isModalOpen} setIsOpen={closeModal} />
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon
                aria-hidden="true"
                className="block size-6 group-data-[open]:hidden"
              />
              <XMarkIcon
                aria-hidden="true"
                className="hidden size-6 group-data-[open]:block"
              />
            </DisclosureButton>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex items-center select-none bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 bg-clip-text text-transparent">
              <img
                src={logo}
                alt="Logo"
                className="h-8 w-8 mr-3 pointer-events-none"
              />
              <a href="/">
                <span className="text-lg pointer-events-none">
                  SAFEWEBCHECKER.COM
                </span>
              </a>
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {navigationLinks.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    aria-current={item.current ? "page" : undefined}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "rounded-md px-3 py-2 text-sm font-medium"
                    )}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {user ? (
              <Menu as="div" className="relative ml-3">
                <div>
                  <MenuButton className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <img alt="User avatar" src={avatar} className="h-8 w-8 rounded-full" />
                  </MenuButton>
                </div>
                <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5">
                  {/* <MenuItem> */}
                  {/*   <a */}
                  {/*     href="/payment" */}
                  {/*     className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" */}
                  {/*   > */}
                  {/*     Planes y Servicios */}
                  {/*   </a> */}
                  {/* </MenuItem> */}
                  <MenuItem>
                    <a
                      href="/settings"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Configuración
                    </a>
                  </MenuItem>
                  <ButtonSessions openModal={toggleModal} />
                </MenuItems>
              </Menu>
            ) : (
              <div className="hidden md:flex items-center space-x-4">
                <ButtonSessions openModal={toggleModal} />
              </div>
            )}
          </div>
        </div>
      </div>
      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {navigationLinks.map((item) => (
            <DisclosureButton
              key={item.name}
              as="a"
              href={item.href}
              aria-current={item.current ? "page" : undefined}
              className={classNames(
                item.current
                  ? "bg-gray-900 text-white"
                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                "block rounded-md px-3 py-2 text-base font-medium"
              )}
            >
              {item.name}
            </DisclosureButton>
          ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
  );}
