import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from '../../utils/AxiosConfig';
import Cookies from 'js-cookie';
import UserService from '../../Api/UserService'; // Asegúrate de tener este archivo configurado

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Estado para manejar la carga

  /**
   * Obtiene el usuario actual si el token existe.
   */
  const fetchCurrentUser = async () => {
    try {
      const token = Cookies.get('token');
      if (token) {
        // Configurar el encabezado Authorization si el token existe
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await UserService.getCurrentUser();
        setUser(response.data);
      } else {
        setUser(null);
      }
    } catch (error) {
      setUser(null);
    } finally {
      setLoading(false); // Finalizar la carga independientemente del resultado
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  /**
   * Función para iniciar sesión.
   * @param {Object} credentials - Objeto con email y password.
   */
  const login = async ({ email, password }) => {
    try {
      const response = await UserService.login({ email, password });
      const { token, user } = response.data;
      Cookies.set('token', token, {
        expires: 1, // El token expira en 1 día
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'Strict',
      });
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUser(user);
      return { success: true };
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      return { success: false, message: error.response?.data?.error || 'Error al iniciar sesión' };
    }
  };

  /**
   * Función para registrar un nuevo usuario.
   * @param {Object} data - Objeto con email, password y password_confirmation.
   */
  const register = async ({ email, password, password_confirmation }) => {
    try {
      const response = await UserService.register({ email, password, password_confirmation });
      const { token, user } = response.data;
      Cookies.set('token', token, {
        expires: 1, // El token expira en 1 día
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'Strict',
      });
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUser(user);
      return { success: true };
    } catch (error) {
      console.error('Error al registrar:', error);
      return { success: false, message: error.response?.data?.error || 'Error al registrar' };
    }
  };

  /**
   * Función para cerrar sesión.
   */
  const logout = async () => {
    try {
      await UserService.logout(); // Si tu API soporta logout
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    } finally {
      Cookies.remove('token');
      delete axiosInstance.defaults.headers.common['Authorization'];
      setUser(null);
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
};