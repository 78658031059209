import React, { createContext, useState } from 'react';

export const ValueContext = createContext();

// Creating a context that takes the value of an input and allows it to be used in any component
export const ValueProvider = ({ children }) => {
  const [value, setValue] = useState('');
  const [type, setType] = useState('');

  return (
    <ValueContext.Provider value={{ value, setValue, type, setType }}>
      {children}
    </ValueContext.Provider>
  );
};
