import React, { useState, useContext } from "react";
import { ValueContext } from "../context/ValueContext";
import { AuthContext } from "../context/AuthContext";
import AuthModal from "./ModalAuthentication";
import WebsiteService from "../../Api/WebsiteService";

const SearchBar = ({
  placeholder = "Search...",
  categories = ["Website"], //  , "Phone", "Crypto", "IBAN", "License", "Instagram", "Facebook"
}) => {
  const { setValue } = useContext(ValueContext);
  const { user } = useContext(AuthContext);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const [authModal, setAuthModal] = useState(false);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setError(""); // Reset error on category change
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (error) setError(""); // Clear error when typing
  };

  const handleAnalyze = async () => {
    if (!inputValue.trim()) {
      setError(`Please enter a valid ${selectedCategory.toLowerCase()}.`);
      return;
    }
    if (user === null) {
      // Si el usuario no está autenticado, mostrar el modal de autenticación
      setAuthModal(true);
    } else {
      // Si el usuario está autenticado, setear el valor en el contexto
      setValue(inputValue);
      try {
        const response = await WebsiteService.crearWebsite(inputValue);
        console.log("crearWebsite response: ", response);
      } catch (error) {
        throw error;
      }
      console.log("Valor seteado en el contexto:", inputValue);
    }
  };

  return (
    <div className=" text-gray-200 p-6 px-32 mt-5">
      {/* Modal de autenticación */}
      <AuthModal isOpen={authModal} setIsOpen={setAuthModal} />

      {/* <div  className="flex w-full md:w-auto overflow-x-auto">
        <button className="flex-shrink-0 px-4 py-2 font-semibold text-xs md:text-sm lg:text-base transition duration-200 focus:outline-none bg-gradient-to-r from-blue-500 to-purple-600 text-white mb-5">Website</button>
      </div> */}

      {/* Sección de la barra de búsqueda */}
      <div className="flex flex-wrap items-center border-none  overflow-hidden w-full">
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 items-center w-full ">
          {/* Categorías */}
          {/* <div className="flex w-full md:w-auto overflow-x-auto">
            {categories.map((category, index) => (
              <button
                key={category}
                onClick={() => handleCategoryClick(category)}
                className={`flex-shrink-0 px-4 py-2 font-semibold text-xs md:text-sm lg:text-base transition duration-200 focus:outline-none ${
                  selectedCategory === category
                    ? "bg-gradient-to-r from-blue-500 to-purple-600 text-white"
                    : "bg-gray-800 text-gray-400 hover:bg-gray-700 hover:text-white"
                }`}
                style={{ minWidth: "100px" }}
              >
                {category.toUpperCase()}
              </button>
            ))}
          </div> */}

          {/* Input de búsqueda */}
          <div className="relative w-full md:flex-grow">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder={`${placeholder} for ${selectedCategory.toLowerCase()}...`}
              className="w-full px-4 py-3 bg-black text-gray-200 placeholder-gray-400 border-0 outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm md:text-base lg:text-lg rounded-md"
            />
            <div className="absolute inset-0 pointer-events-none border border-gradient-to-r from-blue-500 to-purple-600 rounded-md"></div>
          </div>

          {/* Botón de análisis */}
          <button
            onClick={handleAnalyze}
            className="w-full md:w-auto bg-gradient-to-r from-blue-500 to-purple-600 px-4 py-3 text-white"
          >
            Analyze
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
