import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import HomePage from './pages/homepage';
import TestingPage from './pages/TestingPage';
import PaymentStripePage from './pages/PaymentStripePage';
import { AuthProvider } from './components/context/AuthContext';
import { ValueProvider } from './components/context/ValueContext';
import MobileOnlyPage from './pages/MobilePage';
import Settings from '../src/pages/settings';

const App = () => {
  return (
    <AuthProvider>
      <ValueProvider>
        <Router>
          <MainLayout>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/testing" element={<TestingPage />} />
              <Route path="/payment" element={<PaymentStripePage />} />
              <Route path="/mobile" element={<MobileOnlyPage />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
          </MainLayout>
        </Router>
      </ValueProvider>
    </AuthProvider>
  );
};

export default App;