// Función para detectar el tipo de entrada
export const detectInputType = (inputValue) => {
  console.log('detectInputType');

  if (/^\d+$/.test(inputValue)) {
    // Contiene solo números, podría ser un número de teléfono
    return "phone";
  } else if (/^https?:\/\//i.test(inputValue) || inputValue.includes(":")) {
    // Contiene "http" o "https" al inicio, o contiene ":" en cualquier parte, es un enlace
    return "Website";
  } else if (/[A-Za-z]/.test(inputValue) && /\d/.test(inputValue)) {
    // Contiene tanto letras como números, pero no es un enlace, es un identificador policiaco
    return "policeIdentifier";
  } else {
    // No coincide con ninguno de los patrones anteriores
    return "unknown";
  }
};