import React from "react";
import MainInput from "../common/MainInput";
import ScamsByCategory from "./ScamsByCategory";

const HeroSection = () => {
  return (
    <div className="relative  text-gray-200 py-16 sm:py-24 overflow-hidden">
      {/* Decorative Elements */}
      <div className="absolute inset-0 pointer-events-none">
        {/* Snake-Like Moving Lines */}
        <div className="absolute inset-0 flex overflow-hidden">
          <div className="absolute top-5 md:top-10 left-0 w-[200%] sm:w-[300%] h-1 bg-gradient-to-r from-purple-600 to-pink-500 animate-snake-move"></div>
          <div className="absolute bottom-5 md:bottom-10 right-0 w-[200%] sm:w-[300%] h-1 bg-gradient-to-r from-pink-500 to-purple-600 animate-snake-move-reverse"></div>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-12 text-center relative z-10">
        {/* Main Title */}
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text">
          Bienvenido a SafeWebChecker
        </h1>
        <p className="text-base sm:text-lg md:text-2xl text-gray-300 mt-4">
          Tu herramienta confiable para detectar estafas y mantenerte seguro en
          línea.
        </p>





              {/* Report Statistics */}
              <div className="mt-8  text-center">
          {/* <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold bg-gradient-to-r from-purple-500 to-pink-600 text-transparent bg-clip-text">
            Estafas Reportadas Este Año
          </h2> */}
          <p className="text-base sm:text-lg md:text-xl text-gray-300 mt-2">
            Más de{" "}
            <span className="font-extrabold bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text">
              5,600
            </span>{" "}
            estafas han sido detectadas hasta ahora.
          </p>
          <MainInput/>
          <ScamsByCategory/>
        </div>
      </div>
      
      {/* Styles for Animations */}
      <style>
        {`
          /* Slow spinning animation for the glowing rings */
          @keyframes spin-slow {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          .animate-spin-slow {
            animation: spin-slow 12s linear infinite;
          }
  
          /* Reverse spin animation */
          @keyframes spin-reverse {
            0% {
              transform: rotate(360deg);
            }
            100% {
              transform: rotate(0deg);
            }
          }
          .animate-spin-reverse {
            animation: spin-reverse 12s linear infinite;
          }
  
          /* Glowing pulse animation for the rings */
          @keyframes pulse {
            0%, 100% {
              opacity: 0.5;
            }
            50% {
              opacity: 1;
            }
          }
          .animate-pulse {
            animation: pulse 3s ease-in-out infinite;
          }
  
          /* Snake-like line animation */
          @keyframes snake-move {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(100%);
            }
          }
          .animate-snake-move {
            animation: snake-move 10s linear infinite;
          }
  
          /* Reverse snake line animation */
          @keyframes snake-move-reverse {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
          .animate-snake-move-reverse {
            animation: snake-move-reverse 10s linear infinite;
          }
        `}
      </style>
      
    </div>
  );
};

export default HeroSection;
