import React from 'react';
import { Link } from 'react-router-dom';
import { ShieldCheckIcon } from '@heroicons/react/24/solid'; // Icono representativo de seguridad

const Footer = () => {
  return (
    <footer className="bg-black text-white-300 py-6">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
        {/* Información de Derechos con Icono */}
        <div className="flex items-center">
          <ShieldCheckIcon className="h-6 w-6 text-blue-400 mr-2" />
          <p className="text-sm font-medium">
            © 2024 <span className="text-white">SAFEWEBCHECKER.COM</span>. Todos los derechos reservados.
          </p>
        </div>

        {/* Enlaces de Navegación */}
        <div className="flex space-x-4 mt-4 md:mt-0">
          <Link 
            to="/terminos" 
            className="text-sm font-medium hover:text-blue-400 transition-colors"
          >
            Términos de Servicio
          </Link>
          <Link 
            to="/privacidad" 
            className="text-sm font-medium hover:text-blue-400 transition-colors"
          >
            Política de Privacidad
          </Link>
          <Link 
            to="/contacto" 
            className="text-sm font-medium hover:text-blue-400 transition-colors"
          >
            Contacto
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
