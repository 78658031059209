// services/CommentService.js
import axiosInstance from '../utils/AxiosConfig';
import {detectInputType} from '../utils/functions/InputType';
import { formatURL } from '../utils/functions/Format';
// Crear comentario
export const createComment = async (content, data) => {
  var type = detectInputType(data);
  if(type == 'Website'){
    data = formatURL(data)
  }
  try {
    const response = await axiosInstance.post('/crear_comentarios', { 
      content, 
      type, 
      data,
    });
    return response.data;
  } catch (error) {
    console.error('Error al crear comentario:', error);
    throw error;
  }
};

// Buscar comentarios
// @type es el nombre de la tabla a la que nos vamos a referir
// @data el valor que le mandamos.
export const searchComments = async (data) => {
  var type = detectInputType(data);
  if(type == 'Website'){
    data = formatURL(data)
  }
  try {
    const response = await axiosInstance.post('/obtener_comentarios', {
    table: type, data: data ,
    });
    return response.data;
  } catch (error) {
    console.error('Error al buscar comentarios:', error);
    throw error;
  }
};