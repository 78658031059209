import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";

const ChatApp = () => {
  const [socketInstance, setSocketInstance] = useState(null); // Almacenar la instancia del socket
  const [messages, setMessages] = useState([]); // Almacenar los mensajes recibidos
  const [sendMessage, setSendMessage]= useState("");
  // Conectar al servidor WebSocket al montar el componente
  useEffect(() => {
    const socket = io("http://localhost:9090");


    // Escuchar mensajes del servidor
    socket.on("server_message", (data) => {
      console.log("Mensaje recibido del servidor:", data);
    });
    setSocketInstance(socket); // Almacenar la instancia del socket
    // Limpiar la conexión al desmontar el componente
    return () => {
      socket.disconnect();
    };
  }, []);

  // Enviar un mensaje al servidor
  const enviar = () => {
    console.log("Enviando mensaje...", sendMessage);
    socketInstance.emit("client_message", sendMessage);
  };

  const handleChange = (value) => {
    setSendMessage(value);
  }
  return (
    <>
      <input 
      onChange={(event) => handleChange(event.target.value)}
      className="text-black"></input>
      <button
        onClick={enviar}
        className="ml-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
      >
        Send
      </button>
    </>
  );
};

export default ChatApp;
