import React, { useState, useContext, useEffect } from "react";
import { ChatBubbleLeftRightIcon, PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { ValueContext } from '../context/ValueContext';
import { createComment, searchComments } from "../../Api/CommentService";

const UserComments = () => {
  const { value } = useContext(ValueContext); // el valor que se usará:
  const [comments, setComments] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [authModal, setAuthModal] = useState(false);

  useEffect(() => {
    if (!value) return;

    const fetchComments = async () => {
      try {
        const response = await searchComments(value);
        setComments(response);
      } catch (error) {
        console.error("Error fetching comments: ", error);
      }
    };

    fetchComments();
  }, [value]);

  const handleInput = (newValue) => {
    setInputValue(newValue);
  };

  const handleSaveComment = async () => {
    console.log('handleSaveComment Init...');
    try {
      const response = await createComment(inputValue, value);
      setComments((prevComments) => [response, ...prevComments]); // Añadir el nuevo comentario a la lista
      setInputValue(""); // Limpiar el campo de entrada
      console.log(response);
    } catch (error) {
      console.error("Error saving comment: ", error);
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <div className="w-full text-gray-200 shadow-lg p-6 px-32">
      {/* Título */}
      <div className="flex items-center mb-6">
        <ChatBubbleLeftRightIcon className="h-10 w-10 text-primary mr-3" />
        <h2 className="text-3xl font-extrabold text-gray-200">
          Comentarios de Usuarios
        </h2>
      </div>

      {/* Mostrar Comentarios */}
      <div className="space-y-6 mb-8">
        {comments && comments.length > 0 ? (
          [...comments].reverse().map((comment, index) => (
            <div
              key={index} // Usa un ID único si está disponible
              className="bg-background text-gray-300 p-6 shadow-md border-l-8 border-primary rounded-lg"
            >
              <p className="text-base leading-relaxed mb-3">{comment.content}</p>
              <div className="flex items-center justify-between text-sm">
                <p className="text-gray-200 font-semibold">
                  {comment.user || "Usuario Anónimo"}
                </p>
                <span className="text-gray-400">
                  {new Date(comment.created_at).toLocaleString("es-ES", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="text-gray-400 text-lg text-center">
            No hay comentarios disponibles.
          </div>
        )}
      </div>

      {/* Input para agregar nuevo comentario */}
      <div className="flex flex-col sm:flex-row items-center w-full space-y-3 sm:space-y-0 sm:space-x-4">
        <input
          onChange={(e) => handleInput(e.target.value)}
          value={inputValue}
          type="text"
          placeholder="Escribe tu comentario aquí..."
          className="flex-grow p-4 text-gray-200 placeholder-gray-500 bg-background focus:outline-none w-full border-2 border-gray-500 focus:border-primary transition-all rounded-lg"
          disabled={showLoading}
        />
        <button
          onClick={handleSaveComment}
          className={`bg-primary hover:bg-accent text-white p-4 flex items-center justify-center w-full sm:w-auto transition duration-300 rounded-lg ${
            showLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={showLoading}
        >
          <PaperAirplaneIcon className="h-6 w-6 mr-2 transform rotate-90" />
          Enviar
        </button>
      </div>
    </div>
  );
};

export default UserComments;
