// services/RatingService.js
import axiosInstance from '../utils/AxiosConfig';
import { formatURL } from '../utils/functions/Format';
import { detectInputType } from '../utils/functions/InputType';
export const createRating = async (data, score) => {

  let type = detectInputType(data);
  let table = "";
  if(type == "Website"){
    table = "Website";
    type = "url";
    data = formatURL(data);
  }
  try {
    const response = await axiosInstance.post('/createRating', { data, table, score, type });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRating = async (data) => {
  let type = detectInputType(data);
  let table = "";
  if(type == "Website"){
    table = "Website";
    type = "url";
    data = formatURL(data);
  }
  try {
    const response = await axiosInstance.post('/getRating', { data, table, type });
    return response.data;
  } catch (error) {
    throw error;
  }
}

