import React, { useState, useEffect } from "react";
import UserProfile from "../Api/Perfil";

const AccountSettings = () => {
  const [profile, setProfile] = useState({
    avatar_url: null,
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    timezone: "GMT",
  });

  const [imageLoading, setImageLoading] = useState(true);
  const [credits, setCredits] = useState(100); // Example of available credits

  const delete_account = () => {
    console.log('delete account');
  }

  useEffect(() => {
    mostrarPerfil();
  }, []);

  const mostrarPerfil = async () => {
    try {
      const datos = await UserProfile.mostrar_perfil();
      console.log("Profile data fetched:", datos);

      setProfile({
        avatar_url: datos.data.avatar_url || "https://via.placeholder.com/150", // Fallback to default image
        first_name: datos.data.first_name || "",
        last_name: datos.data.last_name || "",
        email: datos.data.email || "",
        username: datos.data.username || "",
        timezone: datos.data.timezone || "GMT",
      });
      setImageLoading(false); // Image loading finished
      
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64DataURL = reader.result;

        setProfile((prevState) => ({
          ...prevState,
          avatar_url: base64DataURL,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const guardar = async () => {
    console.log("Sending updated profile:", profile);
    try {
      await UserProfile.actualizar_perfil(profile);
      console.log("Profile updated successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
    }
    window.location.reload(); // Reload the page
  };

  return (
    <div className="bg-gray-900 min-h-screen py-12 text-gray-200">
      <div className="container mx-auto px-6 max-w-4xl">
        <h1 className="text-3xl font-extrabold mb-8 text-gray-100">Account Settings</h1>

        {/* Profile Picture Section */}
        <section className="bg-gray-800 shadow p-6 mb-8 rounded-lg">
          <h2 className="text-xl font-semibold mb-4 text-gray-100">Profile Picture</h2>
          <div className="flex items-center space-x-6">
            <div className="relative w-28 h-28">
              {imageLoading ? (
                <div className="w-full h-full flex items-center justify-center bg-gray-700 rounded-full">
                  <span className="text-gray-400 text-sm">Loading...</span>
                </div>
              ) : (
                <img
                  src={profile.avatar_url || "https://via.placeholder.com/150"}
                  alt="Profile"
                  className="w-full h-full object-cover rounded-full border-4 border-gray-700 shadow-md"
                  onLoad={() => setImageLoading(false)}
                  onError={() =>
                    setProfile((prevState) => ({
                      ...prevState,
                      avatar_url: "https://via.placeholder.com/150", // Fallback in case of error
                    }))
                  }
                />
              )}
            </div>
            <div>
              <label
                htmlFor="profile-image-upload"
                className="cursor-pointer bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
              >
                Change Avatar
              </label>
              <input
                id="profile-image-upload"
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleImageChange}
              />
              <p className="text-sm text-gray-400 mt-3">JPG, GIF, or PNG. 1MB max.</p>
            </div>
          </div>
        </section>

        {/* Credits Section */}
        <section className="bg-gray-800 shadow p-6 mb-8 rounded-lg">
          <h2 className="text-xl font-semibold mb-4 text-gray-100">Available Credits</h2>
          <p className="text-lg text-gray-400 mb-2">
            Manage your credits for additional services or features.
          </p>
          <p className="text-4xl font-bold text-blue-500">{credits}</p>
        </section>

        {/* Personal Information Section */}
        <section className="bg-gray-800 shadow p-6 mb-8 rounded-lg">
          <h2 className="text-xl font-semibold mb-4 text-gray-100">Personal Information</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-300">First Name</label>
              <input
                type="text"
                name="first_name"
                value={profile.first_name}
                onChange={handleInputChange}
                className="mt-2 block w-full bg-gray-700 border border-gray-600 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-100 px-4 py-2"
                placeholder="John"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300">Last Name</label>
              <input
                type="text"
                name="last_name"
                value={profile.last_name}
                onChange={handleInputChange}
                className="mt-2 block w-full bg-gray-700 border border-gray-600 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-100 px-4 py-2"
                placeholder="Doe"
              />
            </div>
            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-300">Email Address</label>
              <input
                type="email"
                name="email"
                value={profile.email}
                disabled
                className="mt-2 block w-full bg-gray-700 border border-gray-600 text-gray-400 px-4 py-2 cursor-not-allowed"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300">Username</label>
              <input
                type="text"
                name="username"
                value={profile.username}
                onChange={handleInputChange}
                className="mt-2 block w-full bg-gray-700 border border-gray-600 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-100 px-4 py-2"
                placeholder="johndoe"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300">Timezone</label>
              <select
                name="timezone"
                value={profile.timezone}
                onChange={handleInputChange}
                className="mt-2 block w-full bg-gray-700 border border-gray-600 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-100 px-4 py-2"
              >
                <option value="GMT">GMT</option>
                <option value="PST">PST</option>
                <option value="EST">EST</option>
                <option value="CET">CET</option>
                <option value="IST">IST</option>
              </select>
            </div>
          </div>
        </section>

        {/* Save Changes Button */}
        <button
          onClick={guardar}
          className="w-full bg-blue-500 text-white py-2 font-medium hover:bg-blue-600 rounded-lg"
        >
          Save Changes
        </button>

        <button
          onClick={delete_account}
          className="w-full mt-8 bg-red-500 text-white py-2 font-medium hover:bg-blue-600 rounded-lg"
        >
          Delete Account
        </button>
      </div>
    </div>
  );
};

export default AccountSettings;