import React, { useEffect, useState } from "react";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";


const ShowLoading = ({ showLoading, message, timer = 60000, onClose }) => { // Set timer to 1 minute (60000 ms)
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (showLoading) {
      const timerId = setTimeout(() => {
        setShowError(true);
      }, timer);
      
      return () => clearTimeout(timerId);
    }
  }, [showLoading, timer]);

  const handleClose = () => {
    setShowError(false);
    if (onClose) {
      onClose(); // Call onClose prop to handle closing modal externally
    }
  };

  if (!showLoading && !showError) return null;

  return (
    <>
      {/* Fondo oscuro translúcido */}
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
        {/* Contenedor con efecto de vidrio actualizado con un diseño más moderno */}
        <div className="glass-container flex flex-col items-center justify-center p-10 rounded-2xl shadow-xl relative max-w-lg">
          {/* Loader o Mensaje de Error */}
          {showError ? (
            <div className="flex flex-col items-center">
              <p className="text-red-500 text-lg font-semibold mb-4">El análisis ha demorado más de lo esperado. Por favor, inténtalo nuevamente.</p>
              <button
                onClick={handleClose}
                className="bg-red-600 hover:bg-red-700 text-white py-3 px-6 rounded-lg shadow-md transition duration-300"
              >
                Aceptar
              </button>
            </div>
          ) : (
            <>
              {/* Loader */}
              <div className="loader-wobble relative flex items-center justify-center mb-8">
                <ShieldCheckIcon className="h-16 w-16 text-accent animate-pulse absolute" />
              </div>

              {/* Barra de carga */}
              <div className="w-full bg-gray-800 rounded-full h-3 mt-6 mb-6">
                <div className="bg-green-500 h-3 rounded-full loading-bar" style={{ animationDuration: `${timer}ms` }}></div>
              </div>

              {/* Mensaje de carga */}
              {message && (
                <p className="text-white text-xl font-semibold mt-6 animate-pulse text-center">
                  {message}
                </p>
              )}
            </>
          )}
        </div>
      </div>

      {/* Estilos personalizados */}
      <style>
        {`
          /* Barra de carga animada */
          .loading-bar {
            animation: loadingBarAnimation linear forwards;
            width: 0%;
          }

          @keyframes loadingBarAnimation {
            to {
              width: 100%;
            }
          }
          /* Paleta de colores:
             - #006270 (Primario Oscuro)
             - #009394 (Primario Claro)
             - #00E0C7 (Neón Verde)
             - #FFFFFF (Blanco)
          */

          /* Contenedor con efecto de vidrio */
          .glass-container {
            background: rgba(255, 255, 255, 0.15); /* Fondo claro translúcido */
            backdrop-filter: blur(12px); /* Efecto de vidrio más suave */
            -webkit-backdrop-filter: blur(12px); /* Safari */
            border: 1px solid rgba(255, 255, 255, 0.2); /* Bordes más definidos */
            max-width: 90%; /* Evita que se expanda demasiado en pantallas pequeñas */
            min-width: 320px; /* Ancho mínimo */
            padding: 2.5rem; /* Espaciado interno */
            text-align: center; /* Centrado de contenido */
          }

          /* Loader principal */
          .loader-wobble {
            position: relative;
            width: 9em;
            height: 9em;
          }

          /* Loader círculos */
          .loader-wobble::before,
          .loader-wobble::after {
            content: '';
            position: absolute;
            width: 9em;
            height: 9em;
            top: 0;
            left: 0;
            border-radius: 50%;
          }

          /* Anillo interior */
          .loader-wobble::before {
            box-shadow: inset 0 0 0 0.2em rgba(0, 224, 199, 0.6); /* Verde Neón */
            animation: loadingInner 1.2s infinite ease-in-out alternate;
          }

          /* Anillo exterior */
          .loader-wobble::after {
            border: 0.4em dashed #006270; /* Primario Oscuro */
            border-top: 0.4em solid #009394; /* Primario Claro */
            border-radius: 50%;
            transform-origin: center center;
            animation: loadingOuter 1.5s infinite linear;
          }

          /* Animaciones */
          @keyframes loadingOuter {
            to {
              transform: rotate(360deg);
            }
          }

          @keyframes loadingInner {
            to {
              transform: scale(0);
            }
          }
        `}
      </style>
    </>
  );
};

export default ShowLoading;