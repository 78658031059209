import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext'; // Importación correcta
import { useNavigate } from 'react-router-dom';

/**
 * Modal para autenticación (login y registro).
 */
export default function AuthModal({ isOpen, setIsOpen }) {
  const [isRegistering, setIsRegistering] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const { login, register } = useContext(AuthContext);
  const navigate = useNavigate();

  /**
   * Resetea los campos del formulario.
   */
  const resetFields = () => {
    setEmail('');
    setPassword('');
    setPasswordConfirmation('');
    setMessage('');
    setIsSuccess(false);
  };

  /**
   * Maneja el inicio de sesión con Google.
   */
/**
 * Maneja el inicio de sesión con Google.
 */
  const handleGoogleLogin = () => {
    const clientId = '366360225974-rks1blmhif9i75aj9algdhls89dms6hi.apps.googleusercontent.com'

    const isProduction = window.location.hostname !== 'localhost';
    const baseURL = isProduction
    ? 'https://safewebchecker.francolemon.duckdns.org/auth/google_oauth2/callback' // URL de producción
    : 'http://localhost:3000/auth/google_oauth2/callback'; // URL de desarrollo

    const redirectUri = baseURL;


    const scope = 'email profile';
    const responseType = 'code';
    const accessType = 'offline';
    const prompt = 'select_account';

    // Construir la URL de autenticación
    const authUrl = `https://accounts.google.com/o/oauth2/auth?response_type=${responseType}&client_id=${encodeURIComponent(
      clientId
    )}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(
      scope
    )}&access_type=${accessType}&prompt=${prompt}`;

    // Redirigir al usuario a Google OAuth2
    window.location.href = authUrl;
  };

  /**
   * Maneja el inicio de sesión del usuario.
   * @param {Event} e - Evento de envío del formulario.
   */
  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      await login({ email, password });
      setMessage('Sesión iniciada con éxito, redirigiendo...');
      setIsSuccess(true);
      setTimeout(() => {
        resetFields();
        setIsOpen(false);
        navigate('/'); // Redirige a la página de inicio o donde desees
      }, 2000);
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      setIsSuccess(false);
      setMessage(
        error.response?.data?.errors?.[0] ||
          'Error al iniciar sesión. Por favor verifica tus credenciales.'
      );
    }
  };

  /**
   * Maneja el registro de un nuevo usuario.
   * @param {Event} e - Evento de envío del formulario.
   */
  const handleRegister = async (e) => {
    e.preventDefault();
    setMessage('');
    if (password !== passwordConfirmation) {
      setMessage('Las contraseñas no coinciden.');
      setIsSuccess(false);
      return;
    }
    try {
      await register({ email, password, password_confirmation: passwordConfirmation });
      setMessage('Registro exitoso, redirigiendo...');
      setIsSuccess(true);
      setTimeout(() => {
        resetFields();
        setIsOpen(false);
        navigate('/'); // Redirige a la página de inicio o donde desees
      }, 2000);
    } catch (error) {
      console.error('Error al registrar:', error);
      setIsSuccess(false);
      setMessage(
        error.response?.data?.errors?.[0] ||
          error.response?.data?.[0] ||
          'Error al registrar.'
      );
    }
  };

  /**
   * Cierra el modal y resetea los campos.
   */
  const handleClose = () => {
    setIsOpen(false);
    resetFields();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative">
        {/* Botón para cerrar el modal */}
        <button
          onClick={handleClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
          aria-label="Cerrar modal"
        >
          &times;
        </button>

        {/* Título */}
        <h2 className="text-2xl font-semibold mb-4 text-center text-black">
          {isRegistering ? 'Regístrate' : 'Inicia Sesión'}
        </h2>

        {/* Mensaje de recomendación */}
        <p className="text-center text-sm text-gray-700 mb-4">
          Para una mejor experiencia, te recomendamos estar autenticado y
          obtener créditos. Revisa nuestros{' '}
          <a href="/payment" className="text-blue-500 hover:underline">
            planes
          </a>
          .
        </p>

        {/* Mensaje de éxito o error */}
        {message && (
          <p
            className={`text-center mb-4 ${
              isSuccess ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {message}
          </p>
        )}

        {/* Formulario de Login / Registro */}
        <div className="mb-4">
          <form
            className="flex flex-col space-y-4"
            onSubmit={isRegistering ? handleRegister : handleLogin}
          >
            <input
              type="email"
              placeholder="Correo Electrónico"
              className="p-2 border border-gray-300 rounded text-black"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Correo Electrónico"
            />
            <input
              type="password"
              placeholder="Contraseña"
              className="p-2 border border-gray-300 rounded text-black"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              aria-label="Contraseña"
            />
            {isRegistering && (
              <input
                type="password"
                placeholder="Confirmar Contraseña"
                className="p-2 border border-gray-300 rounded text-black"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                required
                aria-label="Confirmar Contraseña"
              />
            )}
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
            >
              {isRegistering ? 'Registrarse' : 'Iniciar Sesión'}
            </button>
          </form>
        </div>

        {/* Alternar entre Registro y Login */}
        <p className="text-center text-sm">
          {isRegistering ? (
            <>
              <span className="text-black">¿Ya tienes una cuenta?</span>{' '}
              <button
                className="text-blue-500 hover:underline"
                onClick={() => setIsRegistering(false)}
              >
                Inicia Sesión
              </button>
            </>
          ) : (
            <>
              <span className="text-black">¿No tienes una cuenta?</span>{' '}
              <button
                className="text-blue-500 hover:underline"
                onClick={() => setIsRegistering(true)}
              >
                Regístrate
              </button>
            </>
          )}
        </p>

        {/* Enlace para recuperar contraseña */}
        <div className="mt-2 text-center">
          <button
            type="button"
            className="text-sm text-blue-500 hover:underline"
            onClick={() => console.log('¿Olvidaste tu contraseña?')}
          >
            ¿Olvidaste tu contraseña?
          </button>
        </div>

        {/* Inicio de sesión con Google */}
        <div className="mt-4 text-center w-full">
          <button
            onClick={handleGoogleLogin}
            className="relative flex items-center justify-center w-full px-4 py-3 space-x-4 border border-gray-300 rounded-lg shadow-sm hover:shadow-md hover:border-gray-500 focus:border-gray-700 transition duration-200 ease-in-out"
          >
            <div className="flex items-center justify-center w-6 h-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                className="w-full h-full"
                aria-hidden="true"
              >
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                />
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                />
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                />
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                />
                <path fill="none" d="M0 0h48v48H0z" />
              </svg>
            </div>
            <span className="text-sm font-medium text-gray-700">
              Iniciar sesión con Google
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
