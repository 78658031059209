import React from "react";

const HowItWorksSection = () => {
  return (
    <section className="w-full">
      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 mb-5">
        {/* Bloque Análisis de Seguridad */}
        <div className="flex flex-col items-center p-8 rounded-lg shadow-lg bg-[#121D30] ">
          <div className="flex items-center justify-center w-16 h-16 mb-4 bg-[#121D30] rounded-full">
            <span className="text-2xl font-bold">🔍</span>
          </div>
          <h3 className="text-xl font-semibold mb-4 text-white">
            Análisis de Seguridad
          </h3>
          <p className="text-[#A6DDF0] text-center">
            Utilizamos IA y ciberseguridad avanzada para analizar sitios web,
            identificando posibles riesgos y vulnerabilidades en segundos.
          </p>
        </div>

        {/* Bloque Puntaje de Confianza */}
        <div className="flex flex-col items-center p-8 rounded-lg shadow-lg bg-[#121D30] ">
          <div className="flex items-center justify-center w-16 h-16 mb-4 bg-[#121D30] rounded-full">
            <span className="text-2xl font-bold">⭐</span>
          </div>
          <h3 className="text-xl font-semibold mb-4 text-white">
            Puntaje de Confianza
          </h3>
          <p className="text-[#A6DDF0] text-center">
            Basamos el puntaje de seguridad en factores como la encriptación y
            la reputación, ayudándote a tomar decisiones seguras.
          </p>
        </div>

        {/* Bloque Comentarios de Usuarios */}
        <div className="flex flex-col items-center p-8 rounded-lg shadow-lg bg-[#121D30]">
          <div className="flex items-center justify-center w-16 h-16 mb-4 bg-[#121D30] rounded-full">
            <span className="text-2xl font-bold">💬</span>
          </div>
          <h3 className="text-xl font-semibold mb-4 text-white">
            Comentarios de Usuarios
          </h3>
          <p className="text-[#A6DDF0] text-center">
            Nuestra comunidad comparte sus experiencias para que puedas saber si
            un sitio es confiable. ¡Agrega tus propios comentarios!
          </p>
        </div>
      </div>
      {/* Nueva Sección: ¿Por qué deberías revisar una dirección web? */}
      <div className="w-full max-w-5xl mx-auto  text-[#27E6EC] p-8 rounded-lg shadow-lg mb-0">
        <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center">
          ¿Por qué deberías revisar una dirección web?
        </h2>

        <ul className="space-y-4 text-gray-300">
          <li className="flex items-start space-x-2">
            <span className="text-[#27E6EC] font-bold">1.</span>
            <div>
              <strong>Protección personal:</strong> Asegúrate de no acceder a
              sitios peligrosos que puedan robar tu información personal o
              infectar tu dispositivo con virus y malware.
            </div>
          </li>
          <li className="flex items-start space-x-2">
            <span className="text-[#27E6EC] font-bold">2.</span>
            <div>
              <strong>Compras en línea seguras:</strong> Antes de hacer una
              compra en un sitio web que no conoces, revisa la dirección web
              para asegurarte de que es confiable y seguro.
            </div>
          </li>
          <li className="flex items-start space-x-2">
            <span className="text-[#27E6EC] font-bold">3.</span>
            <div>
              <strong>Seguridad empresarial:</strong> Las empresas pueden
              verificar sitios web desconocidos para proteger los datos y la
              privacidad de sus empleados y clientes, evitando ataques de
              phishing.
            </div>
          </li>
          <li className="flex items-start space-x-2">
            <span className="text-[#27E6EC] font-bold">4.</span>
            <div>
              <strong>Protección para niños:</strong> Los padres pueden
              verificar si un sitio web es seguro antes de permitir que sus
              hijos naveguen por él, manteniéndolos alejados de contenido
              inadecuado o peligroso.
            </div>
          </li>
          <li className="flex items-start space-x-2">
            <span className="text-[#27E6EC] font-bold">5.</span>
            <div>
              <strong>Verificar correos electrónicos sospechosos:</strong> Si
              recibes un correo electrónico con un enlace que parece sospechoso,
              puedes revisar la dirección web para asegurarte de que no es un
              intento de fraude.
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default HowItWorksSection;
